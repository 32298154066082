/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

#tpl_cms .menu-container .logo-wrapper {
  display: block;
}

